import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import PorticaLogo from '../components/portica-logo';
import { getConsent } from '../services/consent';
import SEO from '../components/seo';

const APP_URL = process.env.GATSBY_APP_URL;

const SignInHelp = () => {
  const [consentStatus, setConsentStatus] = useState(getConsent());

  let woopra = null;
  if (typeof window !== 'undefined') {
    woopra = window.woopra || null;
  }

  useEffect(() => {
    if (consentStatus === 'update-here') {
      if (woopra) {
        woopra.track('sign-in-help_accessed');
      }
    }
  }, [consentStatus]);

  return (
    <div>
      <Helmet>
        <script>
          {consentStatus === 'update-here'
            ? `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2930840,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
            : 'console.log("No consent")'}
        </script>
      </Helmet>
      <SEO
        title="Sign in help | Portica - All your projects in one place"
        description="Do you need help logging in into Portica? Portica is a all-in-one project manager, created by Architects for Architects and Design Professionals. A digital home for your projects"
      ></SEO>
      <div className="sign-in-wrapper">
        <div className="sign-in-wrapper__content">
          <PorticaLogo></PorticaLogo>
          <div className="signin-help">
            <h1 className="title is-size-4 has-text-centered">Need help?</h1>
            <h3 className="subtitle">Are you registered?</h3>
            <p>
              If you aren't registered yet, you can do it here:{' '}
              <a href={`${APP_URL}/users/create`}>Sign Up</a>.
            </p>
            <h3 className="subtitle">Forgot your password?</h3>
            <p>
              You can reset your password here:{' '}
              <a href={`${APP_URL}/users/forgot_password`}>Reset Password</a>
            </p>
            <h3 className="subtitle">Get in touch</h3>
            <p>
              Contact us:{' '}
              <a href="mailto:info@porticapro.com">info@porticapro.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInHelp;
